@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

// @font-face {
//   font-family: 'Visual-Regular';
//   src: url('./assets/fonts/Visual-Regular.eot'); /* IE9 Compat Modes */
//   src: url('./assets/fonts/Visual-Regular.eot?#iefix') format('embedded-opentype'),
//     /* Open Type Font */ url('./assets/fonts/Visual-Regular.svg') format('svg'),
//     /* Legacy iOS */ url('./assets/fonts/Visual-Regular.ttf') format('truetype'),
//     /* Safari, Android, iOS */ url('./assets/fonts/Visual-Regular.woff') format('woff'),
//     /* Modern Browsers */ url('./assets/fonts/Visual-Regular.woff2') format('woff2'); /* Modern Browsers */
//   font-weight: normal;
//   font-style: normal;
// }

.product-content {
  background: transparent;
}

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.product-listing-page .navbar-brand {
  opacity: 0;
}

h1, h2, h3, h4, h5, h6, strong, b {
  //text-transform: uppercase;
  font-weight: 500 !important;
}

#root {
  padding-top: 7rem;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm;
  }
}
.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

.checkout .when-valid {
  background-color: #000;
}

.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background: transparent;

  &.offcanvas-collapse-right {
    background: rgba($color: $black, $alpha: 0.9);
    h5 {color: white;}
  }

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}

// NAV

.navbar-dark {
  background: transparent !important;
  transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: $black !important;
  }
}

.product-listing-page .navbar-brand {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.1s;
  transition-delay: 0;
}

body[data-scrolled] {
  .navbar-brand {
    opacity: 1;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}


.navbar-dark .offcanvas-collapse {
  .navbar-nav .nav-item .nav-link {
    //font-family: 'Visual-Regular', $font-family-sans-serif;
    //text-transform: uppercase;
    font-size: 1.3rem;
    background-color: $darkgray;
  }
}

.maki.card .card-body {
  padding: $card-spacer-x;
  .card-title {
    span.title {
      font-weight: 500;
      //font-family: 'Visual-Regular', $font-family-sans-serif;
      font-size: 1.2rem;
      //text-transform: uppercase;
      max-width: 310px;
      @extend .text-truncate;
    }
    span.sub-title {
      font-size: smaller;
      margin-bottom: 5px;
    }
    span.price {
      font-size: 1rem;
    }
  }
}

.maki.card {
  &:hover {
    background-color: $darkgray;
    color: $white;
  }
}

.table {
  @extend .table-dark;
}

.card,
.maki.card,
.navbar-light .offcanvas-collapse {
  color: $black;
}

#header > .navbar {
  height: auto;
  background-color: $black;
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }

  .navbar-brand {
    @include media-breakpoint-up(md) {
      img {
        height: 4rem;
        max-height: 4rem;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }
}

#footer {
  color: $white;
  background-color: $darkgray;
  a {color: $white}
}

.offcanvas-collapse {
  @include media-breakpoint-up(md) {
    top: 8rem;
  }
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.when-valid {
  background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

.maki .card-body .badge-danger {
  display: none;
}